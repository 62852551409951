<template>
  <div>
    <div class="flex flex-col">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 md:mb-10">
        <div class="col-span-1">
          <router-link :to="{ name: 'marketplace' }" class="inline-flex mt-2"
            ><ion-icon
              name="arrow-back-outline"
              class="text-deal text-xl mr-2"
            ></ion-icon>
            <h1 class="text-13 text-gray-700 font-bold mt-1">
              All Deals
            </h1>
          </router-link>
        </div>
      </div>
      <div class="flex justify-start mt-10" v-if="deals.loading">
        <sm-loader></sm-loader>
      </div>
      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 w-full md:w-8/12"
        v-else
      >
        <deal
          v-for="(deal, i) in deals.data"
          :deal="deal"
          :key="i"
          @selectVendor="selectVendor"
        ></deal>
      </div>
      <no-result v-if="noDealFound">
        <p class="text-12 pt-6 pb-6 opacity-50 text-credpal-700">
          No result found for this category
        </p>
      </no-result>
    </div>

    <branch
      :vendor="selectedVendor"
      v-if="selectedVendor"
      :key="componentKey"
      @success="success"
      ref="branch"
    />
  </div>
</template>

<script>
export default {
  components: {
    Branch: () => import("./components/branch/Index"),
    Deal: () => import("./components/Deal"),
    NoResult: () => import("./components/NoResult")
  },
  data() {
    return {
      deals: this.$options.resource([]),
      selectedVendor: null,
      componentKey: 0
    };
  },
  computed: {
    noDealFound() {
      return !this.deals.loading && this.deals?.data.length === 0;
    }
  },
  beforeMount() {
    this.getAllDeals();
  },
  watch: {
    "selectedVendor.business_name"() {
      this.componentKey += 1;
    }
  },
  methods: {
    async getAllDeals() {
      this.deals.loading = true;
      await this.sendRequest("personal.marketplace.deals", {
        success: response => (this.deals.data = response.data.data),
        error: error => console.log(error)
      });
      this.deals.loading = false;
    },
    selectVendor(vendor) {
      this.selectedVendor = { ...vendor };
      this.$nextTick(() => {
        this.componentKey += 1;
        setTimeout(() => this.$refs.branch.open(), 300);
      });
    },
    success() {
      this.componentKey += 1;
      this.$refs.branch.close();
      this.$refs.successModal.openSuccessModal();
    }
  }
};
</script>
